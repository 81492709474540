@font-face {
  font-family: 'Segoe UI';
  font-weight: 400;
  font-style: normal;
  src: url('./fonts/Segoe UI.ttf') format('truetype');
}
@font-face {
  font-family: 'Segoe UI';
  font-weight: 700;
  src: url('./fonts/Segoe UI Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Segoe UI';
  font-style: italic;
  src: url('./fonts/Segoe UI Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'Segoe UI';
  font-weight: 700;
  font-style: italic;
  src: url('./fonts/Segoe UI Bold Italic.ttf') format('truetype');
}

body {
  margin: 0;
  font-family:  'Segoe UI', -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("images/main-background.jpg");
  background-attachment: fixed;
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
